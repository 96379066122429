import React, { useState } from 'react'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import SEO from '../components/seo'
import CategoryItem from '../components/category'

const MotionContainer = styled.div`
  margin-top: 3.13rem;
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  &:after {
    content: ' ';
    flex: auto;
  }
  @media screen and (max-width: 768px) {
    padding-bottom: 100px;
  }
`

const MotionPage = ({ data }) => {
  const allContentfulCategory = data.contentfulCategoryList.categories
  return (
    <Layout>
      <SEO title="Motion" />
      <MotionContainer>
        {allContentfulCategory.map((category) => (
          <CategoryItem key={category.id} {...category} />
        ))}
      </MotionContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query CategoryPageQuery {
    contentfulCategoryList(name: { eq: "Motion" }) {
      categories {
        id
        title
        slug
        images {
          fixed(width: 544, height: 306) {
            src
          }
        }
      }
    }
  }
`

export default MotionPage
