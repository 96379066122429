import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const MotionItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 4rem;
  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }
`

const StyledLink = styled(Link)`
  width: calc(50% - 3.13rem);
  color: black;
  text-decoration: none;

  @media screen and (max-width: 768px) {
    width: calc(50% - 1rem);
  }
`

const Title = styled.span`
  margin-top: 0.72rem;
`

const defaultImage = [
  {
    src: `//via.placeholder.com/500x300.jpeg`,
  },
]

const CategoryItem = ({ slug, images, id, title }) => {
  images =
    (images && images.map((img) => ({ src: img.fixed.src }))) || defaultImage
  const [imageIndex, setImageIndex] = useState(0)
  const [imagesPreloaded, setImagesPreloaded] = useState(false)
  const [loadedImages, setLoadedImages] = useState(images)

  const preloadImages = async () => {
    const promises = images.map((image) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = image.src
        img.onload = () => {
          resolve(img)
        }
      })
    })

    const loadedImages = await Promise.all(promises)
    setLoadedImages(loadedImages)
    setImagesPreloaded(true)
  }

  const changeImage = (e) => {
    let x
    switch (e.type) {
      case 'touchmove':
        x = e.nativeEvent.pageX
        break
      case 'mousemove':
        x = e.nativeEvent.offsetX
        break
    }

    if (imagesPreloaded) {
      const percentage =
        (x / e.nativeEvent.target.clientWidth) * images.length - 1
      if (percentage >= 0 && percentage <= 100)
        setImageIndex(Math.floor(percentage))
    } else {
      preloadImages()
    }
  }

  useEffect(() => {
    if (!imagesPreloaded) {
      preloadImages()
    }
  }, [imagesPreloaded, preloadImages])

  return (
    <StyledLink
      to={`/category/${slug}`}
      onTouchMove={changeImage}
      onMouseMove={changeImage}
    >
      <MotionItem key={id}>
        <img
          src={
            loadedImages[
              imageIndex >= 0 && imageIndex <= loadedImages.length - 1
                ? imageIndex
                : 0
            ].src
          }
        />
        <Title>{title}</Title>
      </MotionItem>
    </StyledLink>
  )
}

export default CategoryItem
